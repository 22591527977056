import React from 'react';
import classNames from 'classnames';

import styles from '../Pricing.module.scss';
import Accordion from '../../../components/Accordion';
import Icon from '../../../components/icons/Icon';
import { eduPricingFAQ } from '../../../services/pricingFAQService';

const EDUGameplayAccordion = () => (
  <Accordion
    itemHeaderClassName={styles.questionHeader}
    renderExpandIcon={(expanded) => (
      <Icon
        name="arrowDown"
        className={classNames({
          [styles.arrowDown]: !expanded,
          [styles.arrowUp]: expanded,
        })}
        aria-hidden
      />
    )}
    items={eduPricingFAQ.experiences.map((qAndA) => ({
      title: <div className={styles.questionTitle}>{qAndA.q}</div>,
      body: (expanded) => (
        <div className={styles.answerText}>{typeof qAndA.a === 'string' ? qAndA.a : qAndA.a(expanded ? 0 : -1)}</div>
      ),
    }))}
  />
);

export default EDUGameplayAccordion;
