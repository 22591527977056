import React, { MouseEventHandler } from 'react';
import { eduPricingFAQ } from '../../services/pricingFAQService';

import { Helmet } from 'react-helmet';

import FAQGoose from './media/faqGoose.svg';
import EDUQuoteGeese from './media/eduQuoteGeese.svg';

import EDUGameplayAccordion from './components/EDUGameplayAccordion';
import EDULicensesAccordion from './components/EDULicensesAccordion';
import Grid from '../../components/layout/Grid';
import Link from '../../components/Link';
import Page, { Section } from '../../components/Page';
import PriceTier from './components/PriceTier';
import RequestQuoteForm from '../../components/RequestQuoteForm';
import routes from '../../routes';
import styles from './Pricing.module.scss';
import Flex from '../../components/layout/Flex';
import PriceTiersSection from './components/PriceTiersSection';
import WhyBuySubscribeSection from '../WhyBuyASubscription/components/WhyBuySubscribeSection';
import EndlessBenefit from '../../media/benefitsIcons/endlessBenefit.svg';
import RoomBenefit from '../../media/benefitsIcons/roomBenefit.svg';
import VIPBenefit from '../../media/benefitsIcons/vipBenefit.svg';
import FlyingColorsBenefit from '../../media/benefitsIcons/flyingColorsBenefit.svg';
import WhyBuyHappyCustomersSection from '../WhyBuyASubscription/components/WhyBuyHappyCustomersSection';
import CustomerCard from '../WhyBuyASubscription/components/CustomerCard';
import Image from '../../components/Image';
import eduJeremyMcIver from './media/eduJeremyMcIver.jpg';
import eduJessieErickson from './media/eduJessieErickson.jpg';
import eduShelleyMann from './media/eduShelleyMann.jpg';
import WhyBuyMainSection from '../WhyBuyASubscription/components/WhyBuyMainSection';
import EduSubscriptionsMainImg from './media/eduSubscriptionsMain.svg';

const k12El = <>K&#8209;12</>; // Non wrapping '-' so K and 12 don't get split
const reqQuoteFormId = 'eduPricingReqQuote';
const commonFeatures = [
  'Access to 250+ pre-built Experience templates',
  'Compatible with tablets like Chromebooks and iPads',
  'Participant login without personal email',
  'Real-time activity feed',
];

const EduPricing = () => {
  const trackCtaClick =
    (eventId: string): MouseEventHandler<HTMLAnchorElement> =>
    async (e) => {
      e.preventDefault();
      // Events get reused so we need to copy the target before awaiting to ensure we have it when
      // the promise resolves
      const target = e.currentTarget.href;
      window.location.href = target;
    };

  return (
    <Page
      title="Pricing for K-12 Teachers, Schools & Districts | Goosechase"
      description="Compare special pricing plans specifically designed for K-12 teachers, schools and districts. Create limitless learning experiences. Try it for free!"
    >
      <Helmet>
        {/* For structured schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'FAQPage',
            mainEntity: eduPricingFAQ.licenses
              .map((qAndA) => ({
                '@type': 'Question',
                name: qAndA.q,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: qAndA.aStringMarkup || qAndA.a,
                },
              }))
              .concat(
                eduPricingFAQ.experiences.map((qAndA) => ({
                  '@type': 'Question',
                  name: qAndA.q,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: qAndA.aStringMarkup || qAndA.a,
                  },
                }))
              ),
          })}
        </script>
      </Helmet>

      <WhyBuyMainSection
        heading={
          <>
            Create "Oh yeah!" and "I get it!" moments.
            <br />
            On repeat.
          </>
        }
        subHeading={
          <>
            Give every student and teacher new, creative, and collaborative experiences that make learning more fun and
            effective than traditional methods. School and district-wide subscriptions with unlimited Goosechase
            Experiences, participants, and co-managers makes it that much easier.
          </>
        }
        image={<EduSubscriptionsMainImg css={{ width: '100%' }} />}
      />

      <PriceTiersSection
        bg="cyan"
        topContent={
          <div className={styles.eduTitleContainer}>
            <h1 className={styles.title}>Educator pricing</h1>
            <p className={styles.eduSubtitle}>
              Create limitless learning experiences with annual plans specifically tailored for {k12El} teachers,
              schools, and districts. <strong>Accessible only to verified {k12El} schools.</strong>
            </p>
          </div>
        }
        priceTiers={[
          {
            title: 'Educator Basic',
            borderTopColor: 'none',
            price: '$0',
            priceDetails: (
              <div>
                USD
                <br />
                per Experience
              </div>
            ),
            ctaLink: (
              <PriceTier.CtaLink
                genericCta
                edu
                variant="btnSm"
                id="educator-basic-cta"
                onClick={trackCtaClick('educator-basic-pricing-tier-cta-click')}
              >
                Try for free
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'short',
            mainFeatures: ['Great for small one-off activities'],
            secondaryFeatures: [
              'Team mode only',
              'Up to 5 teams per Experience',
              'Up to 5 mobile devices per team',
              'Unlimited Experiences',
              'Run 1 live Experience at a time',
              ...commonFeatures,
              'Support from the customer success team',
            ],
          },
          {
            title: 'Educator Plus',
            borderTopColor: 'yellow',
            price: '$99',
            priceDetails: (
              <div>
                USD per educator,
                <br />
                per year
              </div>
            ),
            ctaLink: (
              <PriceTier.CtaLink
                genericCta
                edu
                variant="btnSmOutline"
                id="educator-plus-cta"
                onClick={trackCtaClick('educator-plus-pricing-tier-cta-click')}
              >
                Get started
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'short',
            mainFeatures: ['Great for single classrooms'],
            secondaryFeatures: [
              'Up to 10 teams or 40 individual students per Experience',
              'Up to 4 mobile devices per team',
              'Unlimited live Experiences',
              ...commonFeatures,
              'Support from the customer success team',
            ],
          },
          {
            title: 'School 500',
            borderTopColor: 'green',
            price: '$749',
            priceDetails: (
              <div>
                USD per school,
                <br />
                per year
              </div>
            ),
            ctaLink: (
              <PriceTier.CtaLink
                genericCta
                edu
                variant="btnSmOutline"
                id="school-wide-cta"
                onClick={trackCtaClick('school-wide-pricing-tier-cta-click')}
              >
                Get started
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'short',
            mainFeatures: ['For schools with up to 500 students'],
            secondaryFeatures: [
              'Up to 100 teams or 500 individual students per Experience',
              'Up to 5 mobile devices per team',
              'Unlimited live Experiences',
              'Up to 30 creator accounts',
              ...commonFeatures,
              'Support from a dedicated Account Manager',
            ],
          },
          {
            title: 'District & School-wide',
            borderTopColor: 'red',
            price: "Let's talk",
            priceIsWords: true,
            ctaLink: (
              <PriceTier.CtaLink
                href={`#${reqQuoteFormId}`}
                edu
                variant="btnSmOutline"
                id="district-wide-cta"
                onClick={trackCtaClick('district-wide-pricing-tier-cta-click')}
              >
                Contact us
              </PriceTier.CtaLink>
            ),
            mainFeaturesHeight: 'short',
            mainFeatures: ['For districts, and schools with 500+ students'],
            secondaryFeatures: [
              'Unlimited teams and students',
              'Unlimited live Experiences',
              'Unlimited creator accounts',
              ...commonFeatures,
              'Support from a dedicated Account Manager',
            ],
          },
        ]}
        bottomContent={
          <div className={styles.otherPricingContainer}>
            <p>
              Not a K-12 educator?{' '}
              <Link
                href={routes.WHY_BUY_A_SUBSCRIPTION.path}
                useRouter
                variant="underline"
                className={styles.otherPricingLink}
              >
                See non-EDU pricing
              </Link>
            </p>
          </div>
        }
      />
      <WhyBuySubscribeSection
        title="Why should your school or disctrict subscribe?"
        benefits={[
          {
            icon: <EndlessBenefit width={96} height={96} />,
            title: 'Endless activities',
            description: (
              <>
                In-class learning, virtual lessons, field trips, PD Days, PTA activities, staff socials…having{' '}
                <strong>unlimited Goosechase Experiences (yep)</strong> means it's easy to inspire active learning
                anytime.
              </>
            ),
          },
          {
            icon: <RoomBenefit width={96} height={96} />,
            title: 'Room for everyone',
            description: (
              <>
                With <strong>huge participant limits</strong>, hitting capacity is one thing you'll never have to worry
                about. There's a seat for every child, parent, and teacher here.
              </>
            ),
          },
          {
            icon: <VIPBenefit width={96} height={96} />,
            title: 'Easy to manage and share',
            description: (
              <>
                With <strong>unlimited access for your entire school</strong>, every teacher and staff member has the
                tools to create out-of-this-world activities for their students - and themselves!
              </>
            ),
          },
          {
            icon: <FlyingColorsBenefit width={96} height={96} />,
            title: 'Flying colors, every time',
            description: (
              <>
                With a <strong>dedicated account manager</strong>, you can be confident your teachers get the support
                they need to keep students engaged and inspired to learn.
              </>
            ),
          },
        ]}
      />

      <WhyBuyHappyCustomersSection>
        <CustomerCard>
          <CustomerCard.SinglePhoto>
            <Image
              src={eduJessieErickson}
              width="1200"
              height="628"
              alt="Jessie Erickson"
              css={{ height: '100%', objectFit: 'cover', objectPosition: 'center' }}
            />
          </CustomerCard.SinglePhoto>
          <CustomerCard.OrganizationTitle>EDU Ambassador Highlight</CustomerCard.OrganizationTitle>
          <CustomerCard.MainTitle>Jessie Erickson, District Assessment Coordinator</CustomerCard.MainTitle>
          <CustomerCard.Quote>
            Have you ever seen High School teachers run down the hall during "Staff Development" days? I have - they
            were playing a Goosechase Experience!
          </CustomerCard.Quote>
          <div>
            <CustomerCard.ExternalLink href="https://blog.goosechase.com/meet-jessie-erickson/">
              Read their story
            </CustomerCard.ExternalLink>
          </div>
        </CustomerCard>

        <CustomerCard>
          <CustomerCard.SinglePhoto>
            <Image
              src={eduShelleyMann}
              width="1200"
              height="628"
              alt="Shelley Mann"
              css={{ height: '100%', objectFit: 'cover', objectPosition: 'center' }}
            />
          </CustomerCard.SinglePhoto>
          <CustomerCard.OrganizationTitle>EDU Ambassador Highlight</CustomerCard.OrganizationTitle>
          <CustomerCard.MainTitle>Shelley Mann, Instructional Tech Specialist</CustomerCard.MainTitle>
          <CustomerCard.Quote>
            Goosechase has stolen the hearts and minds of educators and students in our district…it’s become a component
            of the classroom curriculum rather than just a special event for a special day.
          </CustomerCard.Quote>
          <div>
            <CustomerCard.ExternalLink href="https://blog.goosechase.com/meet-shelley-mann/">
              Read their story
            </CustomerCard.ExternalLink>
          </div>
        </CustomerCard>

        <CustomerCard>
          <CustomerCard.SinglePhoto>
            <Image
              src={eduJeremyMcIver}
              width="1200"
              height="628"
              alt="Jeremy McIver"
              css={{ height: '100%', objectFit: 'cover', objectPosition: 'center' }}
            />
          </CustomerCard.SinglePhoto>
          <CustomerCard.OrganizationTitle>EDU Ambassador Highlight</CustomerCard.OrganizationTitle>
          <CustomerCard.MainTitle>Jeremy McIver, Grade 8 Health and PE Teacher</CustomerCard.MainTitle>
          <CustomerCard.Quote>
            The information you can ask is almost limitless. Then, getting the chance to see the pictures and videos
            that are submitted is priceless.
          </CustomerCard.Quote>
          <div>
            <CustomerCard.ExternalLink href="https://blog.goosechase.com/meet-jeremy-mciver/">
              Read their story
            </CustomerCard.ExternalLink>
          </div>
        </CustomerCard>
      </WhyBuyHappyCustomersSection>

      <div className={styles.faqBlock}>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Flex flexDirection="column" justifyContent="space-between">
              <h2 className={styles.commonQuestions}>Common Questions</h2>
              <FAQGoose className={styles.faqGoose} width="33rem" />
            </Flex>
          </Grid>
          <Grid item md={8} xs={12}>
            <div className={styles.questionsContainer}>
              <h3 className={styles.questionSectionTitle}>Licenses</h3>
              <EDULicensesAccordion />

              <h3 className={styles.gameplay}>Experiences</h3>
              <EDUGameplayAccordion />
            </div>
          </Grid>
        </Grid>
      </div>

      <Section paddingTop="sm" paddingBot="md">
        <RequestQuoteForm
          id={reqQuoteFormId}
          classes={{ title: styles.eduReqQuoteTitle }}
          title="Get Goosechase for all your teachers and staff!"
          subtitle="Contact us to find the right solution for your school or district."
          entity="School/District"
          nextStepText="Next step? Create your first Experience for free!"
          media={<EDUQuoteGeese className={styles.quoteGeeseImg} />}
          leftAlignFields
          leftAlignTitle
          fullWidthFields
          isEdu
        />
      </Section>
    </Page>
  );
};

export default EduPricing;
